<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'รายงานผล ภาค ก. ผู้สมัครคัดเลือก ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_colleges.period_college_times +
            ' ปี : ' +
            period_colleges.period_college_year
        "
      >
        <div class="text-right font-weight-bold">
          <h3>ภาค ก (กรรมการ ระดับสถานศึกษา ต้นสังกัด)</h3>
          <v-btn color="info" rounded @click="downloadFileA()">
            <v-icon>mdi-download</v-icon> ดาว์นโหลดแบบทำการ ภาค ก.</v-btn
          >

          <v-btn
            color="info"
            rounded
            :href="
              '#/college/print_sedirector_app_score_a_vp/?time=' +
                period_colleges.period_college_times +
                '&year=' +
                period_colleges.period_college_year +
                '&pid=' +
                users.user_code
            "
            target="_blank"
          >
            <v-icon>mdi-printer</v-icon> พิมพ์แบบสรุปผลคะแนนการประเมิน ภาค
            ก.</v-btn
          >
          <h3 class="red--text">
            *รายงานผลการประเมิน ระหว่างวันเวลาที่กำหนด :
            {{
              period_colleges.period_college_start
                | moment("add", "543 year")
                | moment("D MMMM YYYY")
            }}
            -
            {{
              period_colleges.period_college_stop
                | moment("add", "543 year")
                | moment("D MMMM YYYY")
            }}
          </h3>
        </div>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>
          <template v-slot:item.sedirector_app_file="{ item }">
            <v-btn
              large
              text
              rounded
              color="green"
              @click="pdfHrvecRegisDir(item.sedirector_app_file)"
              ><v-icon large>mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.sedirector_app_file_A="{ item }">
            <v-btn
              text
              rounded
              color="green"
              large
              @click="pdfHrvecRegisDir(item.sedirector_app_file_A)"
              ><v-icon large>mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.sedirector_app_pvcscore_A="{ item }">
         
              <div
                v-if="String(item.sedirector_app_pvcscore_A_file).length <= 4"
              >
                <v-btn
                  @click="
                    sedirector_app_pvcscore_Areport(item.sedirector_app_idref)
                  "
                  dark
                  color="warning"
                  rounded
                >
                  <v-icon>
                    mdi-account-plus
                  </v-icon>
                  ไม่ได้รายงานผล
                </v-btn>
              </div>

              <div v-else>
                <v-chip
                  color="success"
                  dark
                  v-if="item.sedirector_app_pvcscore_A >= 60"
                >
                  {{ item.sedirector_app_pvcscore_A }}
                </v-chip>
                <v-chip color="red" dark v-else>
                  {{ item.sedirector_app_pvcscore_A }}
                </v-chip>
              </div>
        
           
          </template>

          <template v-slot:item.sedirector_app_pvcscore_A_file="{ item }">
            <div v-if="item.sedirector_app_pvcscore_A_file">
              <v-btn
                @click="
                  viewsedirector_app_pvcscore_A_file(
                    item.sedirector_app_pvcscore_A_file
                  )
                "
                fab
                small
                color="success"
                dark
              >
                <v-icon>mdi-printer</v-icon>
                <!-- {{ item.sedirector_app_pvcscore_A_file }} -->
              </v-btn>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้รายงานผล
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_AB_CC="{ item }">
            <div v-if="String(item.sedirector_app_pvcscore_A_file).length <= 4">
              <v-chip dark color="warning">
                ไม่ได้รายงานผล
              </v-chip>
            </div>
            <div v-else>
              <v-icon color="success"
                >mdi-checkbox-marked-circle-outline</v-icon
              >
              <br />
              <v-btn
                @click="cancelpvcScoreA(item.sedirector_app_idref)"
                color="warning"
                rounded
                outlined
              >
                <v-icon>mdi-alert</v-icon> ขอยกเลิกรายการ</v-btn
              >
            </div>
            <div v-if="item.sedirector_app_pvcscore_AB_CC === 'ACC'">
              <v-chip color="red" dark>
                <v-icon>mdi-information-outline</v-icon> ขอยกเลิกไฟล์</v-chip
              >
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h4>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model sedirector_app_pvcscore_ADrdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="sedirector_app_pvcscore_ADrdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="รายงานผลการประเมิน ภาค ก (กรรมการ ระดับสถานศึกษา ต้นสังกัด)"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="sedirector_app_pvcscore_ADrdialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h4>
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h4>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvcscore_A
                        "
                        prepend-icon="mdi-calculator"
                        outlined
                        label="ผลการประเมินภาค ก"
                        type="number"
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="8"
                      ><v-file-input
                        v-model="sedirector_app_pvcscore_A_file"
                        accept=".pdf"
                        name="sedirector_app_pvcscore_A_file"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="เอกสารสรุปผลการประเมิน ภาค ก."
                        prepend-icon="mdi-paperclip"
                        outlined
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input></v-col
                    >
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click.stop="sedirector_app_pvcscore_ADrdialog = false"
                fab
                outlined
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                color="success"
                @click.stop="sedirector_app_pvcscore_ASubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfHrvecRegisDirdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model sedirector_app_pvcscore_A_filedialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="sedirector_app_pvcscore_A_filedialog"
          max-width="80%"
        >
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "ลำดับที่สมัคร",
          align: "center",
          value: "sedirector_app_idno"
        },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "ใบสมัครและเอกสารประกอบฯ", align: "center", value: "sedirector_app_file" },
        {
          text: "ภาค ก.",
          align: "center",
          value: "sedirector_app_file_A"
        },
        {
          text: "คะแนน ภาค ก.",
          align: "center",
          value: "sedirector_app_pvcscore_A"
        },

        {
          text: "ไฟล์คะแนน ภาค ก.",
          align: "center",
          value: "sedirector_app_pvcscore_A_file"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "sedirector_app_pvcscore_AB_CC"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_apps_id_ref: [],
      period_colleges: [],
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      users: [],
      sedirector_app_pvcscore_A_filedialog: false,
      pdffiles: "",
      sedirector_app_pvcscore_ADrdialog: false,
      sedirector_app_pvcscore_A_file: null,
      pdfHrvecRegisDirdialog: false,
      buttonsOpenSubmit: ""
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.userQuery();
    await this.period_collegeQuery();
  },
  methods: {
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;
      this.pdfHrvecRegisDirdialog = true;
    },

    async downloadFileA() {
      var downloadLink = document.createElement("a");
      var url = "https://hrvec.ovec.go.th/docEx/A2567.xlsx";
      var filename = "A2567.xlsx";
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "check",
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
    },

    async sedirector_app_pvcscore_Areport(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.sedirector_app_pvcscore_A_file = null;
      this.sedirector_app_pvcscore_ADrdialog = true;
    },
    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_id: "20100"
      });
      this.period_colleges = result_period_college.data;
      let data = this.period_colleges.period_college_enable;

      if (data != 1) {
        Swal.fire({
          icon: "error",
          title: "ระบบไม่เปิดใช้งาน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.sedirector_appQueryAll();
      }
    },
    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 1,
          sedirector_app_time: this.period_colleges.period_college_times,
          sedirector_app_year: this.period_colleges.period_college_year,
          sedirector_app_college: this.users.user_code,
          sedirector_app_pvc_approve: "pass"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;

      let data = this.sedirector_apps;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      if (counts < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่ปรากฎผู้สมัคร",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/vecprovince");
          }
        });
      } else {
        let dateNow = new Date();

        let dateStart = new Date(
          this.period_colleges.period_college_start + " 00:00:01"
        );

        let dateStop = new Date(
          this.period_colleges.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStarts = dateStart.getTime();
        this.dateStops = dateStop.getTime();

        this.interval = setInterval(async () => {
          if (
            this.dateNows < this.dateStops &&
            this.dateNows > this.dateStarts
          ) {
            this.buttonsOpenSubmit = "1";
          } else {
            this.buttonsOpenSubmit = "0";
          }
        }, 3000);
      }
    },

    async sedirector_app_pvcscore_ASubmit() {
      this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
      let uploaded = null;
      let result;
      if (this.$refs.sedirector_app_pvcscore_ADrdialogform.validate()) {
        if (this.sedirector_app_pvcscore_A_file != "") {
          let formData = new FormData();
          let filename =
            this.sedirector_apps_id_ref.sedirector_app_idref +
            this.time_stamp +
            "." +
            "sedirect_A_score.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_pvcscore_A_file);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_A_file = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }

      this.sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CC = "CF";

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps_id_ref
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.sedirector_app_pvcscore_ADrdialog = false;
    },

    async viewsedirector_app_pvcscore_A_file(sedirector_app_pvcscore_A_file) {
      this.pdffiles = sedirector_app_pvcscore_A_file;
      this.sedirector_app_pvcscore_A_filedialog = true;
    },

    async cancelpvcScoreA(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      Swal.fire({
        title: "คุณต้องการขอยกเลิกรายงานผลภาค ก ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ขอยกเลิก",
        cancelButtonText: "ปิด"
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CC = "ACC";

          let result = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    },
    row_classes(item) {
      if (item.sedirector_app_pvcscore_AB_CC == "ACC") {
        return "yellow";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_college_years() {
      let yyyy = this.period_colleges.period_college_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
